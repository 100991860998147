
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
    <path d="M25 2c8.294 0 15 6.939 15 15.521 0 2.692-.82 5.92-2.367 9.554-1.412 3.317-3.383 6.866-5.77 10.527a113.12 113.12 0 0 1-5.72 7.964l-.572.724L25 47l-.571-.71-.572-.724a113.12 113.12 0 0 1-5.72-7.964c-2.387-3.66-4.358-7.21-5.77-10.527C10.82 23.442 10 20.213 10 17.52 10 8.939 16.706 2 25 2zm-1.052 7.848c.556-.855 1.668-1.103 2.513-.562.423.247.69.675.8 1.147.112.496.023.99-.244 1.396l-1.09 1.733c2.58.968 4.426 3.512 4.426 6.46 0 1.44-.444 2.791-1.2 3.894h2.335c.845 0 1.512.698 1.512 1.53 0 .856-.667 1.554-1.512 1.554H18.779a1.509 1.509 0 0 1-1.512-1.53c0-.856.667-1.554 1.512-1.554h6.303c1.535-.607 2.602-2.138 2.602-3.894 0-2.026-1.4-3.691-3.247-4.096l-2.18 3.398-3.069-2.003zm-6.827 8.269a.748.748 0 0 1 1.046-.226l3.936 2.567c.356.225.445.697.223 1.057a.803.803 0 0 1-.645.36.68.68 0 0 1-.4-.112l-3.937-2.566c-.356-.225-.445-.72-.223-1.08z" :fill="'#' + this.color" fill-rule="evenodd"/>
</svg>
</template>

<script>
export default {
  name: 'SamplingServiceIcon',
  props: {
    color: {
      type: String,
    },
  },
};
</script>

<style scoped>

</style>
