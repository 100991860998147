
<template>
  <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
    <defs>
    </defs>
    <g id="conviso">
      <rect class="cls-5" width="200" height="200"/>
      <circle class="cls-4" cx="101.79" cy="103.53" r="68.16"/>
      <circle class="cls-3" cx="101.79" cy="103.53" r="38.5"/>
      <circle class="cls-1" cx="101.79" cy="103.53" r="9.62"/>
      <path class="cls-1" d="m136.08,36.71l-33.89,66.82,66.44-34.25c-7.17-13.97-18.59-25.39-32.55-32.57Z"/>
      <rect class="cls-2" x="56.96" y="108.07" width="19.41" height="19.41"/>
      <rect class="cls-2" x="145.17" y="94.96" width="19.41" height="19.41"/>
      <rect class="cls-2" x="114.85" y="136.34" width="15.53" height="15.53"/>
      <rect class="cls-2" x="70.01" y="158.96" width="10" height="10"/>
      <g>
        <rect class="cls-2" x="25.01" y="27" width="61.95" height="61.95"/>
        <g id="Plants_Weeds" data-name="Plants/Weeds">
          <g id="Weeds">
            <path id="Fill_5" data-name="Fill 5" class="cls-4" d="m47.65,57.96c5.8-1.68,9.25,2.52,10.39,8.79,3.95-4.37,8.66-3.41,10.74.87.09-11.81-11.7-20.01-21.11-25.74,10.06,2.7,21.74,11.47,24.1,21.54-.69-2.96,2.5-6.04,4.96-7.28-3.96-1.58-7.61-9.12-4.41-12.3-6.35.51-12.56-3.68-13.89-11.06-2.53,9.36-17.66,1.29-23.77,4.75,7.89,2.52,15.02,11.64,12.99,20.43Z"/>
            <path id="Fill_8" data-name="Fill 8" class="cls-4" d="m68.74,78.03c-.4.75-1.36,1.38-1.98,1.79l6.82,3.35c6.56-10.84,1.15-25.35-9.19-31.69,7.85,6.15,10.73,18.34,4.36,26.55Z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ConvisoIcon',
};
</script>

<style scoped>
  #conviso .cls-1 {fill: #ff6c00;}
  #conviso .cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {stroke-width: 0px;}
  #conviso .cls-2 {fill: #233737;}
  #conviso .cls-3 {fill: #c8dbd3;}
  #conviso .cls-4 {fill: #fff;}
  #conviso .cls-5 {fill: #257150;}
  #conviso rect.cls-5 {fill: #257150 !important;}
</style>
