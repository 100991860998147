
<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200">
    <g fill="none">
      <rect
          width="200"
          height="200"
          fill="#E8E6D4" />
      <path
          fill="#257150"
          d="M144.102499,41.7772161 C145.715754,40.1657912 148.53895,40.5686474 149.547234,42.5829286 C153.378714,49.4314847 159.630077,65.3443062 156.605224,85.084262 C154.818775,96.1873392 142.677105,126.611245 105.756407,125.065466 C107.138482,120.305334 107.036107,115.432721 105.801147,110.759134 C112.645558,103.740186 119.888878,97.0414429 124.945097,88.9113963 C130.793146,80.2499872 135.834567,69.977153 138.052793,59.7043189 C136.036224,67.7614438 130.793146,75.4157123 125.751724,81.8614121 C119.870066,89.5828234 112.728052,95.9054284 105.492679,102.228033 L103.320433,104.126213 C103.297441,104.14633 103.27445,104.166447 103.251459,104.186566 C100.721643,99.2776391 96.9906865,94.8728898 92.7134498,91.5418778 C90.0173052,89.4421834 87.1112991,87.6742377 84.1145228,86.0570737 C86.8280722,81.5663363 90.4519209,77.557158 94.494911,74.4085716 C99.8283874,70.254985 105.850758,67.1942833 111.908062,64.2284941 L114.703841,62.8627735 C121.221998,59.674859 127.663988,56.4189568 133.213028,51.8486222 C137.044509,48.6257722 140.674332,45.2014942 144.102499,41.7772161 Z" />
      <path
          fill="#96A02D"
          d="M51.8195762,72.9343068 C54.3500414,75.4619017 57.0293576,77.9894967 59.8575247,80.3684096 C63.7462543,83.5712688 68.2290956,85.8995885 72.7901093,88.1407145 L75.2825866,89.359232 C79.8549708,91.5946792 84.4117206,93.8860865 88.4368971,97.0207999 C97.2191,103.860174 103.321987,116.200785 97.5168018,126.905893 C96.0282928,129.58217 93.2001258,132.555811 93.2001258,132.555811 C92.4976354,130.757721 91.4972815,129.076055 90.3977018,127.56423 C89.128884,125.819717 87.7279455,124.301351 86.5000835,123.091206 C85.1845184,121.794624 84.0676373,120.851866 83.5248174,120.363883 C77.1242288,114.713964 70.5747893,109.361411 65.3650078,102.522036 C61.6437355,97.7642101 57.7736121,92.1142919 56.2851031,86.1670097 C57.922463,93.7497946 61.6437355,101.332579 65.9604115,107.725908 C68.8897971,112.436155 72.813745,116.495532 76.8103988,120.528875 L78.8105165,122.546269 C82.4728584,126.251454 86.0671908,130.022269 88.8834498,134.339996 C92.496844,139.826125 94.5664351,149.237478 95.9098862,158.218357 L88.5420886,158.218357 C87.7879552,150.732186 86.6163713,142.699848 84.5667737,136.421545 C84.1202209,135.232088 82.7805629,134.191314 81.4409048,134.339996 C53.1592343,136.272863 43.9304786,113.227144 42.5908205,104.900949 C40.358057,90.3301074 44.9724349,78.5842249 47.8006019,73.5290349 C48.5448564,72.0422145 50.628769,71.7448504 51.8195762,72.9343068 Z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CrossCropSeedserviceIcon',
};
</script>

<style scoped>

</style>